import styled from 'styled-components';
import colors from '../theme/colors';
import { Box } from '@mui/material';

export const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
`;

export const LinkBlue = styled.a`
  text-decoration: none;
  color: ${colors.brandBlue.BB700};
  font-weight: 500;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 300;
  justify-content: flex-start;
  height: 90%;
  max-height: 90%;
  min-height: fit-content;
  flex: 1;
  padding: 10% 8% 0 9%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  @media (max-width: 670px) {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

interface FlexibleBoxInterface{
  hasIcon?: boolean;
}

export const FlexibleBox = styled(Box)<FlexibleBoxInterface>`
  width: ${props => props.hasIcon ? '41%' : '38%'};
  @media (max-width: 925px) {
    width: 100%;
  }
`;

export const FlexibleBoxConfigUnits = styled(Box)`
  width: 48%;
  @media (max-width: 925px) {
    width: 100%;
  }
  @media only screen and (min-width: 1231px) and (max-width: 1522px) {
    width: 60%;
  }
  @media only screen and (min-width: 1040px) and (max-width: 1231px) {
    width: 70%;
  }
  @media only screen and (min-width: 925px) and (max-width: 1040px) {
    width: 80%;
  }
`;

export const ModalContainerFlexible = styled(Box)`
  background-color: ${colors.light.L100};
  height: 40%;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  @media only screen and (min-width: 880px) and (max-width: 1100px) {
    width: 50%;
  }
  @media only screen and (min-width: 670px) and (max-width: 880px) {
    width: 60%;
  }
  @media (max-width: 670px) {
    height: 60%;
    width: 85%;
    padding: 0 20px;
  }
`;
