import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from '@mui/material';
import styled, { css } from 'styled-components';
import colors from '../../global/theme/colors';
import { FC, useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { unitType } from '../../types/types';
import { useAddressAutofillCore } from '@mapbox/search-js-react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { onFullAddressChange, setCnpjInfos, user } from '../../redux/userSlice';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FlexibleBox } from '../../global/styles/style';
import CancelIcon from '@mui/icons-material/Cancel';

const apiKey = process.env.REACT_APP_MAPBOX_API_KEY

const fieldPadding = css`
  .MuiOutlinedInput {
    &-input {
      height: 25px;
      padding: 12px !important;
    }
  }
  .MuiInputLabel-outlined {
    margin-top: -6px;

    &.MuiInputLabel-shrink {
      margin-top: 0;
    }
  }
  .MuiOutlinedInput-multiline {
    padding: 0;
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px !important;
  }
`;

const fieldBorder = css`
  .MuiOutlinedInput {
    &-input {
      z-index: 1;
    }
    &-notchedOutline {
      border-color: #dfe6df;
      background-color: #fafcfa;
      z-index: 0;
    }
  }
  &:hover {
    .MuiInputBase-root:not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline {
        border-color: #c9cbd0;
      }
    }
  }
`;

const fieldLabelColor = css`
  .MuiFormLabel-root {
    white-space: nowrap;
  }
`;

const fieldTypography = css`
  .MuiFormLabel-root,
  .MuiInputBase-input {
    font-family: 'inter', sans-serif !important;
    font-size: 14px;
  }
  .MuiInputBase-root {
    border-color: #c9cbd0;
    min-height: 40px;
    line-height: 1.75;
  }
  .MuiInputBase-root:focus {
    border-color: #c9cbd0;
  }
  .MuiInputBase-input::placeholder {
    color: ${colors.dark.D200};
    opacity: 1;
  }
`;

const fieldAdornmentStyle = css`
  .MuiInputAdornment-root {
    z-index: 3;
  }
  .MuiSelect-icon {
    z-index: 3;
  }
`;

interface PropsStyled {
  mobileWidth?: string;
  normalWidth?: string;
}


const StyledTextField = styled(MuiTextField) <PropsStyled>`
  ${fieldPadding}
  ${fieldBorder}
  ${fieldLabelColor}
  ${fieldTypography}
  ${fieldAdornmentStyle}
  width: ${props => props.normalWidth ? props.normalWidth : '100%'};
  @media (max-width: 500px) {
    width: ${props => props.mobileWidth ? props.mobileWidth : '100%'};
  }
`;

const fieldPaddingComplete = css`
  .MuiOutlinedInput {
    &-input {
      height: 15px;
      padding: 12px !important;
    }
  }
  .MuiInputLabel-outlined {
    margin-top: -6px;

    &.MuiInputLabel-shrink {
      margin-top: 0;
    }
  }
  .MuiOutlinedInput-multiline {
    padding: 0;
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px !important;
  }
`;

const fieldBorderComplete = css`
  .MuiOutlinedInput {
    &-input {
      z-index: 1;
    }
    &-notchedOutline {
      border-color: #dfe6df;
      background-color: #fafcfa;
      z-index: 0;
    }
  }
  &:hover {
    .MuiInputBase-root:not(.Mui-error) {
      .MuiOutlinedInput-notchedOutline {
        border-color: #c9cbd0;
      }
    }
  }
`;

const fieldLabelColorComplete = css`
  .MuiFormLabel-root {
    white-space: nowrap;
  }
`;

const fieldTypographyComplete = css`
  .MuiFormLabel-root,
  .MuiInputBase-input {
    font-family: 'inter', sans-serif !important;
    font-size: 14px;
  }
  .MuiInputBase-root {
    border-color: #c9cbd0;
    min-height: 40px;
    line-height: 1.75;
  }
  .MuiInputBase-root:focus {
    border-color: #c9cbd0;
  }
  .MuiInputBase-input::placeholder {
    color: ${colors.dark.D200};
    opacity: 1;
  }
`;

const fieldAdornmentStyleComplete = css`
  .MuiInputAdornment-root {
    z-index: 3;
  }
  .MuiSelect-icon {
    z-index: 3;
  }
`;


const StyledTextFieldComplete = styled(MuiTextField)`
  ${fieldPaddingComplete}
  ${fieldBorderComplete}
  ${fieldLabelColorComplete}
  ${fieldTypographyComplete}
  ${fieldAdornmentStyleComplete}
  width: 100%;
  ${({ error }) =>
    error &&
    css`
      .MuiFormLabel-root {
        color: red; // Change the label color to red when error is true
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: red; // Change the border color to red when error is true
      }
    `}
`;

export const TextField = ({ select, mobileWidth, normalWidth, ...props }: any) => {
  const selectProps = select && {
    MenuProps: {
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
    },
  };
  return (
    <StyledTextField
      select={select}
      mobileWidth={mobileWidth}
      SelectProps={selectProps}
      normalWidth={normalWidth}
      color="primary"
      variant="outlined"
      {...props}
    />
  );
};

export const TextFieldIcon = ({ select, checkStatus, normalWidth, mobileWidth, disabled, ...props }: any) => {
  const selectProps = select && {
    MenuProps: {
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
    },
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <StyledTextField
        select={select}
        SelectProps={selectProps}
        mobileWidth={mobileWidth}
        normalWidth={normalWidth}
        disabled={disabled}
        color="primary"
        variant="outlined"
        {...props}
      />
      {checkStatus === 3
        ?
        (<CancelIcon sx={{ color: '#e72027', marginTop: '16px' }} />)
        :
        (<CheckCircleIcon sx={{ color: (checkStatus === 0 && disabled) || (checkStatus === 1 && disabled) ? '#dadada' : (checkStatus === 0 || checkStatus === 1) && !disabled ? '#acacac' : '#41a03e', marginTop: '16px' }} />)
      }
    </Box>
  );
};

export const TextFieldMaps = ({ select, mobileWidth, normalWidth, ...props }: any) => {
  const { fullAddress } = useAppSelector(user);
  const dispatch = useAppDispatch();
  const [suggestions, setSuggestions] = useState<any[]>([])
  const [value, setValue] = useState(fullAddress)

  const selectProps = select && {
    MenuProps: {
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
    },
  };

  const autofill = useAddressAutofillCore({ accessToken: apiKey });

  const getSuggestions = async (e: string) => {
    if (!e) {
      return;
    }
    const response = await autofill.suggest(`${e}`, {
      sessionToken: 'test-123'
    });
    setSuggestions(response.suggestions)
    console.log({ response });
  }

  useEffect(() => {
    console.log(fullAddress)
  }, [])

  const setAddressInfos = (address: any) => {
    dispatch(setCnpjInfos({
      street: address.street,
      number: address.address_number,
      district: address.locality ? address.locality : address.neighborhood,
      city: address.place,
      state: address.region_code,
      zipCode: address.postcode,
    }))
  }

  return (
    <Autocomplete
      options={suggestions}
      inputValue={value}
      sx={{
        '& .MuiInputBase-root': { padding: 0, width: '100%' },
      }}
      getOptionLabel={(option) => option.full_address || ''}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          onChange={(e) => {
            setValue(e.target.value);
            getSuggestions(e.target.value);
          }}
          select={select}
          mobileWidth={mobileWidth}
          SelectProps={selectProps}
          normalWidth={normalWidth}
          color="primary"
          variant="outlined"
          {...props}
        />
      )}
      onChange={(event, newValue) => {
        if (newValue && newValue.full_address) {
          console.log(newValue)
          setAddressInfos(newValue)
          setValue(newValue.full_address)
          dispatch(onFullAddressChange(newValue.full_address))
        }
        console.log(newValue)
      }}
      freeSolo
      renderOption={(props, option) => (
        <li {...props}>
          {option.full_address}
        </li>
      )}
    />
  );
};

export const TextFieldPassword = ({
  select,
  error,
  thirdSafe,
  thirdSafeChange,
  helperText,
  ...props
}: any) => {
  const [safe, setSafe] = useState(true);
  return (
    <StyledTextField
      error={error || !!helperText}
      helperText={helperText}
      select={select}
      color="primary"
      mobileWidth={undefined}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                if (typeof thirdSafe !== 'undefined') {
                  thirdSafeChange(!thirdSafe)
                } else {
                  setSafe(!safe)
                }
              }}
              edge="end"
            >
              {safe ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      type={
        typeof thirdSafe !== 'undefined'
          ? thirdSafe
            ? 'password'
            : 'text'
          : safe
            ? 'password'
            : 'text'
      }
      {...props}
    />
  );
};


export const TextFieldUnit = ({ ...props }: any) => {

  return (
    <MuiTextField
      variant="standard"
      style={{
        textAlign: 'center',
        width: '50%',
        height: '100%',
        fontFamily: 'Open Sans, sans-serif',
        color: '#212836',
        fontSize: '14px',
      }}
      inputProps={{
        style: {
          textAlign: 'center',
          fontFamily: 'Open Sans, sans-serif',
        },
      }}
      {...props}
    />
  );
};

interface Props {
  disabled: boolean;
  placeholder: string;
  sx: any;
  options: any[] | unitType[];
  onChange: (event: any, newValue: any) => void;
  valueParent: { number: string, block: string } | null;
}

export const AutocompleteTextField: FC<Props> = ({ options, disabled, onChange, placeholder, valueParent, sx, ...props }) => {
  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    if (valueParent) {
      if (valueParent.number === "ADM") {
        setLocalValue('')
      } else {
        setLocalValue(`${valueParent.number} - ${valueParent.block}`)
      }
    } else {
      setLocalValue('')
    }
  }, [valueParent])

  return (
    <Autocomplete
      sx={{
        '& .MuiInputBase-root': { padding: 0 },
      }}
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        return option.number;
      }}
      renderInput={(params) => (
        <StyledTextFieldComplete
          {...params}
          {...props}
          variant="outlined"
          value={localValue}
          color="primary"
          disabled={disabled}
          placeholder={placeholder}
          sx={[sx, { height: '50px' }]}
        />
      )}
      onChange={(event, newValue) => {
        onChange(event, newValue);
      }}
      value={localValue}
      freeSolo
      renderOption={(props, option) => (
        <li {...props}>
          {option.number} - {option.block}
        </li>
      )}
    />
  );
};