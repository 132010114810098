import axios from 'axios';

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const condofy = axios.create({
  baseURL: apiBaseUrl,
});

export const condofyPrivate = (token: string) => axios.create({
  baseURL: apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
});
