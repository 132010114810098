import {
  Box,
  Grid,
  Modal,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { NextAndPrevComponents } from '../../@condofy-components/nextAndPrevComponents';
import { NextButton } from '../../@condofy-components/nextButton';
import { TextField } from '../../@condofy-components/textField';
import { Header, Label, SubHeader } from '../../@condofy-components/typoFonts';
import {
  FlexibleBox,
  MainContainer,
  ModalContainerFlexible,
  SubContainer,
} from '../../global/styles/style';
import colors from '../../global/theme/colors';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { onActiveAlertError } from '../../redux/alertSlice';
import { auth, cnpjCheck, setLoading } from '../../redux/authSlice';
import {
  getAddressByCep,
  getCnpjInfo,
  onCepChange,
  onCityChange,
  onCleanCnpjInfos,
  onCnpjChange,
  onCustomCondoNameChange,
  onDistrictChange,
  onFullAddressChange,
  onNumberChange,
  onStateChange,
  onStreetChange,
  setDoNotKnowCnpj,
  user,
} from '../../redux/userSlice';
import { CNPJIsValid } from '../../utils';
import { cnpjMask } from '../../utils/masks';

export const AboutCondominium = () => {
  const {
    cnpj,
    legalName,
    city,
    number,
    state,
    street,
    district,
    zipCode,
    tradeName,
    doNotKnowCnpj,
    customCondoName,
  } = useAppSelector(user);
  const dispatch = useAppDispatch();
  const { loadingAuth } = useAppSelector(auth);
  const [showModalError, setShowModalError] = useState(false);
  const [showModalCnpjInvalid, setShowModalCnpjInvalid] = useState(false);
  const [isAddressFieldsDisabled, setIsAddressFieldsDisabled] = useState(true);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const lg = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        let buttonElement = document.getElementById('nextButton');
        if (buttonElement) {
          buttonElement.click();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const cpfAleradyExist = () => {
    if (!CNPJIsValid(cnpj)) {
      setShowModalCnpjInvalid(true);
      return;
    }
    dispatch(cnpjCheck({ cnpj }))
      .then(response => {
        if (response.payload) {
          setShowModalError(true);
        } else {
          if (cnpj.length === 18) {
            getCnpj();
          } else if (cnpj.length < 18) {
            dispatch(onCleanCnpjInfos({}));
          }
          setShowModalError(false);
        }
      })
      .catch(() => {
        setShowModalError(false);
      });
  };

  useEffect(() => {
    if (cnpj.length === 18) {
      cpfAleradyExist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpj]);

  const getCnpj = () => {
    dispatch(setLoading(true));
    dispatch(getCnpjInfo({ cnpj })).then(response => {
      if (response.payload) {
        dispatch(setLoading(false));
      } else {
        dispatch(onActiveAlertError('CNPJ inválido.'));
        dispatch(onCnpjChange(''));
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    if (zipCode.length === 8) {
      getCep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode]);

  const getCep = () => {
    dispatch(setLoading(true));
    dispatch(getAddressByCep({ zipCode })).then(response => {
      if (response.payload) {
        dispatch(setLoading(false));
        setIsAddressFieldsDisabled(false);
      } else {
        dispatch(onActiveAlertError('CEP inválido.'));
        dispatch(onCepChange(''));
        dispatch(setLoading(false));
        setIsAddressFieldsDisabled(true);
      }
    });
  };

  useEffect(() => {
    if (doNotKnowCnpj) {
      dispatch(onCnpjChange(''));
      dispatch(onCleanCnpjInfos({}));
    } else {
      dispatch(onCustomCondoNameChange(''));
      dispatch(onFullAddressChange(''));
      dispatch(onCleanCnpjInfos({}));
    }
  }, [doNotKnowCnpj]);

  const maskCep = (cep: string) => {
    if (cep.length === 8) {
      return `${cep.slice(0, 5)}-${cep.slice(5, 8)}`;
    }
    return cep;
  };

  const dataIsValid =
    cnpj.length === 18 &&
    !loadingAuth &&
    city &&
    number &&
    state &&
    street &&
    district &&
    zipCode;

  const labelDefaultStyle = { marginTop: '16px' };
  const textFieldDefaultStyle = { marginTop: '16px' };
  /* const labelDefaultStyle = { marginTop: lg ? '8px' : '16px' };
  const textFieldDefaultStyle = { marginTop: lg ? '4px' : '16px' }; */

  return (
    <MainContainer
      data-testid="about-condominium"
      style={{
        height: md ? 'calc(100vh - 20dvh - 5px)' : '100vh',
        minHeight: md ? 'calc(100vh - 20dvh - 5px)' : '100vh',
      }}
    >
      <SubContainer
        style={{
          paddingTop: md ? '10px' : '10%',
          /* height: sm ? 'calc(100% - 20dvh)' : '90%',
          maxHeight: sm ? 'calc(100% - 20dvh)' : '90%', */
        }}
      >
        <Header
          style={{
            margin: 0,
            marginTop: !md ? -70 : 0,
          }}
        >
          Sobre seu condomínio
        </Header>
        <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
          Digite o número de CNPJ do seu condomínio e revise os dados antes de
          continuar.
        </SubHeader>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <Switch
            checked={doNotKnowCnpj}
            onChange={(_e, checked) => dispatch(setDoNotKnowCnpj(checked))}
          />
          <Typography variant="body2" sx={{ marginLeft: 1 }}>
            Não sei ou não tenho o CNPJ
          </Typography>
        </Box>

        {!doNotKnowCnpj && (
          <>
            <Label style={{ ...labelDefaultStyle }}>
              Qual o CNPJ do seu condomínio?
            </Label>
            <FlexibleBox
              sx={{
                width: sm ? '280px' : md ? '480px' : lg ? '580px' : '670px',
              }}
            >
              <TextField
                data-testid="cnpj-condominium"
                placeholder="CNPJ do condomínio"
                sx={{ ...textFieldDefaultStyle }}
                value={cnpjMask(cnpj)}
                disabled={doNotKnowCnpj}
                onChange={(e: any) => dispatch(onCnpjChange(e.target.value))}
              />
              {dataIsValid && (
                <Box
                  sx={{
                    display: 'flex',
                    height: 88,
                    marginBottom: 2,
                    marginTop: '5px',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors.brandBlue.BB700,
                      width: 2,
                      height: '100%',
                      borderTopLeftRadius: 2,
                      borderBottomLeftRadius: 2,
                    }}
                  ></Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      justifyContent: 'center',
                      height: '100%',
                      width: '100%',
                      backgroundColor: colors.light.L200,
                      paddingLeft: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        color: colors.dark.D600,
                        fontFamily: 'Manrope, sans-serif',
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      {tradeName
                        ? tradeName
                        : legalName
                        ? legalName
                        : 'Sem nome'}
                    </Typography>
                    <Typography
                      sx={{
                        color: colors.dark.D300,
                        fontFamily: 'Manrope, sans-serif',
                        fontWeight: 400,
                        fontSize: 14,
                      }}
                    >
                      {street}, {number}, {district}, {city}, {state}
                    </Typography>
                  </Box>
                </Box>
              )}
            </FlexibleBox>
          </>
        )}
        {doNotKnowCnpj && (
          <>
            <FlexibleBox
              sx={{
                marginBottom: '20px',
                // marginBottom: sm ? '4px' : '20px',
                width: sm ? '280px' : md ? '480px' : lg ? '580px' : '670px',
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{ marginBottom: sm ? '4px' : '0px' }}
              >
                <Grid item xs={12}>
                  <Label style={{ ...labelDefaultStyle }}>
                    Qual o nome do condomínio?
                  </Label>
                  <TextField
                    placeholder="Nome do condomínio"
                    value={customCondoName}
                    onChange={(e: any) =>
                      dispatch(onCustomCondoNameChange(e.target.value))
                    }
                    sx={{ ...textFieldDefaultStyle }}
                  />
                </Grid>
                <Grid item xs={5} md={3}>
                  <Label style={{ ...labelDefaultStyle }}>CEP</Label>
                  <TextField
                    placeholder="CEP"
                    value={maskCep(zipCode)}
                    onChange={(e: any) => dispatch(onCepChange(e.target.value))}
                    sx={{
                      ...textFieldDefaultStyle,
                      // width: sm ? '131%' : '100%',
                    }}
                  />
                </Grid>
                <Grid item xs={9} md={6}>
                  <Label style={{ ...labelDefaultStyle }}>Rua</Label>
                  <TextField
                    placeholder="Rua"
                    value={street}
                    onChange={(e: any) =>
                      dispatch(onStreetChange(e.target.value))
                    }
                    sx={{
                      ...textFieldDefaultStyle,
                      // width: sm ? '131%' : '100%',
                    }}
                    disabled={isAddressFieldsDisabled}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Label style={{ ...labelDefaultStyle }}>Número</Label>
                  <TextField
                    placeholder="Número"
                    value={number}
                    onChange={(e: any) =>
                      dispatch(onNumberChange(e.target.value))
                    }
                    sx={{
                      ...textFieldDefaultStyle,
                      // width: sm ? '131%' : '100%',
                    }}
                    disabled={isAddressFieldsDisabled}
                  />
                </Grid>
                <Grid item xs={11} md={5}>
                  <Label style={{ ...labelDefaultStyle }}>Bairro</Label>
                  <TextField
                    placeholder="Bairro"
                    value={district}
                    onChange={(e: any) =>
                      dispatch(onDistrictChange(e.target.value))
                    }
                    sx={{
                      ...textFieldDefaultStyle,
                      // width: sm ? '131%' : '100%',
                    }}
                    disabled={isAddressFieldsDisabled}
                  />
                </Grid>
                <Grid item xs={9} md={4}>
                  <Label style={{ ...labelDefaultStyle }}>Cidade</Label>
                  <TextField
                    placeholder="Cidade"
                    value={city}
                    onChange={(e: any) =>
                      dispatch(onCityChange(e.target.value))
                    }
                    sx={{
                      ...textFieldDefaultStyle,
                      // width: sm ? '131%' : '100%',
                    }}
                    disabled={isAddressFieldsDisabled}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Label style={{ ...labelDefaultStyle }}>Estado</Label>
                  <TextField
                    placeholder="Estado"
                    value={state}
                    onChange={(e: any) =>
                      dispatch(onStateChange(e.target.value))
                    }
                    sx={{
                      ...textFieldDefaultStyle,
                      // width: sm ? '131%' : '100%',
                    }}
                    disabled={isAddressFieldsDisabled}
                  />
                </Grid>
              </Grid>
            </FlexibleBox>
          </>
        )}
        <Modal
          open={showModalError}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ModalContainerFlexible>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Header>Esse CNPJ já está em uso</Header>
              <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
                Por favor digite um novo CNPJ.
              </SubHeader>
            </Box>
            <Box
              sx={{ display: 'flex', width: '90%', justifyContent: 'center' }}
            >
              <NextButton
                disappear={false}
                customText="Ok"
                disabled={false}
                onClick={() => {
                  dispatch(onCnpjChange(''));
                  setShowModalError(false);
                }}
              />
            </Box>
          </ModalContainerFlexible>
        </Modal>
        <Modal
          open={showModalCnpjInvalid}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ModalContainerFlexible>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Header>CNPJ inválido</Header>
              <SubHeader style={{ margin: '0px 0px 18px 0px' }}>
                Tente novamente com um CNPJ válido.
              </SubHeader>
            </Box>
            <Box
              sx={{ display: 'flex', width: '90%', justifyContent: 'center' }}
            >
              <NextButton
                disappear={false}
                customText="Fechar"
                disabled={false}
                onClick={() => {
                  dispatch(onCnpjChange(''));
                  setShowModalCnpjInvalid(false);
                }}
              />
            </Box>
          </ModalContainerFlexible>
        </Modal>
      </SubContainer>
      <NextAndPrevComponents
        hideReturn={md}
        disableNext={
          doNotKnowCnpj
            ? !customCondoName ||
              !city ||
              !number ||
              !state ||
              !street ||
              !district ||
              !zipCode
            : loadingAuth || !cnpj || !city || !district || !state
        }
      />
    </MainContainer>
  );
};
