import styled from "styled-components";


export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 10%;
  padding: 0 45px;
  @media (max-width: 670px){
    padding: 0 20px;
    justify-content: space-between;
  }
`;
