export function phoneMask(value: string) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
    .replace(/(-\d{4})\d+?$/, '$1');
}

export function cpfMask(value: string) {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export function cnpjMask(value: string) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d{3})/, '$1.$2')
    .replace(/(\d{3})(\d{3})/, '$1.$2')
    .replace(/(\d{3})(\d{4})/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
}

export function adaptiveMask(value: string) {
  const cpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

  if (cpfPattern.test(value) || cpfPattern.test(value.slice(0, 14))) {
    // Apply CPF mask
    return value.slice(0, 14);
  } else {
    if (value.length !== 11) {
      return value; // Return the value as is if it's not 11 characters long
    }

    // Step 2: Check if the value consists only of numbers
    if (!/^\d+$/.test(value)) {
      return value; // Return the value as is if it contains non-numeric characters
    }

    // Step 3: Apply the CPF mask if the value consists only of numbers
    const cpfMask = value.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      '$1.$2.$3-$4'
    );
    return cpfMask.slice(0, 14);
  }
}
