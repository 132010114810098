export function stringCleaner(string: string, limit: number) {
  const regex = /[\s()-/.]/gi;
  const cleanedString = string.replace(regex, '');

  const slicedString = cleanedString.slice(0, limit);

  return slicedString;
}

export function cleanStringIfIfCpf(value: string) {
  const cpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

  if (cpfPattern.test(value) || cpfPattern.test(value.slice(0, 14))) {
    return stringCleaner(value, 11);
  } else {
    return value;
  }
}

export function testIsCpf(value: string) {
  const cpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

  return cpfPattern.test(value);
}

export function CPFIsValid(cpf: string): boolean {
  if (typeof cpf !== 'string') {
    return false;
  }

  cpf = cpf.replace(/[^\d]+/g, '');

  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) {
    return false;
  }

  const digits = cpf.split('').map(el => +el);

  function getVerifyingDigit(arr: number[]) {
    const reduced = arr.reduce(
      (sum, digit, index) => sum + digit * (arr.length - index + 1),
      0
    );
    return ((reduced * 10) % 11) % 10;
  }

  return (
    getVerifyingDigit(digits.slice(0, 9)) === digits[9] &&
    getVerifyingDigit(digits.slice(0, 10)) === digits[10]
  );
}

export function CNPJIsValid(cnpj: string): boolean {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return false;

  if (cnpj.length!== 14) return false;

  const invalidCNPJs = ["00000000000000", "11111111111111", "22222222222222", "33333333333333", "44444444444444", "55555555555555", "66666666666666", "77777777777777", "88888888888888", "99999999999999"];
  if (invalidCNPJs.includes(cnpj)) return false;

  var tamanho = cnpj.length - 2;
  var numeros = cnpj.substring(0, tamanho);
  var digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
  }
  var resultado = soma % 11 < 2? 0 : 11 - soma % 11;
  if (resultado!== parseInt(digitos.charAt(0))) return false;

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2? 0 : 11 - soma % 11;
  if (resultado!== parseInt(digitos.charAt(1))) return false;

  return true;
}


export const getToken = () => {
  const token = window.sessionStorage.getItem('token');
  return token;
};

export function isValidEmail(email: string): boolean {
  return email.includes("@") && email.includes(".com");
}

export function isEmail(s: string): boolean {
  // Regular expression for email validation
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
  
  if (emailRegex.test(s)) {
      return true;
  } else {
      return false;
  }
}
