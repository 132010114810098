const colors = {
  dark: {
    D900: '#000000',
    D700: '#001947',
    D600: '#1A305A',
    D500: '#33476C',
    D400: '#4D5E7E',
    D300: '#667591',
    D200: '#808CA3',
  },
  light: {
    L600: '#C1C7D0',
    L500: '#D7DFE9',
    L400: '#E5EBF2',
    L300: '#F3F5F8',
    L200: '#FAFAFA',
    L100: '#FFFFFF',
  },
  darkBlue: {
    DB700: '#001D80',
    DB600: '#1A348D',
    DB500: '#334A99',
    DB400: '#4D61A6',
    DB300: '#6677B3',
    DB200: '#808EBF',
    DB100: '99A5CC',
  },
  brandBlue: {
    BB700: '#0154EC',
    BB600: '#1A65EE',
    BB100: '#99BBF7',
  },
  lightBlue: {},
  red: {},
  orange: {},
  yellow: {},
  green: {},
  gradient: {},
};

export default colors;
