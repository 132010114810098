import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';
import colors from '../../global/theme/colors';
import styled from 'styled-components';

interface PropsBox {
  disabled: boolean | undefined;
  single?: boolean | undefined;
}

const FlexBox = styled(Box) <PropsBox>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.disabled
    ? colors.brandBlue.BB100
    : colors.brandBlue.BB700};
  width: 188px;
  height: 52px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  border-radius: 0.5;
  &:hover {
    background-color: ${props => props.disabled
    ? colors.brandBlue.BB100
    : colors.brandBlue.BB600
  }};
  user-select: none;
  @media (max-width: 925px){
    width: ${props => props.single ? '100%' : '70%'};
  }
`

interface Props {
  lastStep?: boolean;
  disabled?: boolean;
  onClick: () => void;
  customText?: string;
  disappear?: boolean;
  single?: boolean;
  id?: string;
}

export const NextButton: FC<Props> = ({ lastStep, disabled, onClick, customText, disappear, single, id }) => {
  if (disappear) {
    return (
      <Box></Box>
    )
  }

  return (
    <FlexBox
      id={id}
      single={single}
      disabled={disabled}
    >
      <Button data-testid="next-step" onClick={() => disabled ? {} : onClick()}>

        <Typography
          sx={{
            color: colors.light.L100,
            fontFamily: 'Poppins, sans-serif',
            fontSize: 18,
            fontWeight: 400,
          }}
        >
          {customText ? customText : !lastStep ? 'Continuar' : 'Finalizar'}
        </Typography>
      </Button>
    </FlexBox>
  );
};
