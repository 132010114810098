import styled from 'styled-components';
import colors from '../../global/theme/colors';

export const Header = styled.p`
  color: ${colors.dark.D900};
  font-family: 'Poppins', sans-serif;
  font-size: 1.88rem;
  font-weight: 700;
  @media (max-width: 925px){
    font-size: 1.45rem;
    font-weight: 600;
  }
`;

export const HeaderModal = styled.p`
  color: ${colors.dark.D900};
  font-family: 'Poppins', sans-serif;
  font-size: 1.87rem;
  font-weight: 700;
  @media only screen and (min-width: 1400px) and (max-width: 1540px) {
    font-size: 1.75rem;
  }
  @media only screen and (min-width: 1320px) and (max-width: 1400px) {
    font-size: 1.65rem;
  }
  @media only screen and (min-width: 1220px) and (max-width: 1320px) {
    font-size: 1.55rem;
  }
  @media only screen and (min-width: 1170px) and (max-width: 1220px) {
    font-size: 1.45rem;
  }
  @media only screen and (min-width: 1020px) and (max-width: 1170px) {
    font-size: 1.38rem;
  }
  @media only screen and (min-width: 800px) and (max-width: 1020px) {
    font-size: 1.35rem;
  }
  @media only screen and (min-width: 670px) and (max-width: 800px) {
    font-size: 1.25rem;
  }
  @media only screen and (min-width: 400px) and (max-width: 670px) {
    font-size: 1.7rem;
  }
  @media only screen and (max-width: 400px) {
    font-size: 1.5rem;
  }
`;

export const SubHeader = styled.p`
  color: ${colors.dark.D300};
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 925px){
    font-size: 14px;
  }
`;

export const Label = styled.p`
  color: ${colors.dark.D700};
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  @media (max-width: 925px){
    font-size: 13px;
  }
`;

export const ForgotPassword = styled.p`
  color: ${colors.brandBlue.BB700};
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  margin-top: 12px;
  cursor: pointer;
  @media (max-width: 925px){
    font-size: 13px;
  }
`;
