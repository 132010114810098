import React from 'react';
import EmailOutlined from '../../img/email_outlined.svg';
import CompleteStep from '../../img/step_complete.svg';
import CurrentStep from '../../img/step_current.svg';
import InactiveStep from '../../img/step_inative.svg';
import ArrowLeft from '../../img/arrow_left.svg';
import ArrowLeftDisabled from '../../img/arrow_left_disabled.svg';
import Checked from '../../img/check.svg';
import BuildingCompletedIcon from '../../img/building-completed.svg'
import { Box } from '@mui/material';

export const EmailOutlinedIcon = (props: any) => (
  <img src={EmailOutlined} alt="Email Outlined Icon" {...props} />
);

export const CompleteStepIcon = (props: any) => (
  <img
    src={CompleteStep}
    alt="Complete Step Icon Outlined Icon"
    {...props}
    style={{ height: 30, width: 30 }}
  />
);

export const CurrentStepIcon = (props: any) => (
  <img
    src={CurrentStep}
    alt="Current Step Icon Outlined Icon"
    {...props}
    style={{ height: 30, width: 30 }}
  />
);

export const InactiveStepIcon = (props: any) => (
  <img
    src={InactiveStep}
    alt="Inactive Step Icon Outlined Icon"
    {...props}
    style={{ height: 30, width: 30 }}
  />
);

export const ArrowLeftStepIcon = (props: any) => (
  <img
    src={ArrowLeft}
    alt="Arrow left"
    {...props}
    style={{ height: 20, width: 16 }}
  />
);

export const ArrowLeftStepIconDisabled = (props: any) => (
  <img
    src={ArrowLeftDisabled}
    alt="Arrow left"
    {...props}
    style={{ height: 20, width: 16 }}
  />
);

export const CheckedIcon = (props: any) => (
  <Box sx={{ height: 24, width: 24, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <img
      src={Checked}
      alt="checked icon"
      {...props}
      style={{ height: 18, width: 18 }}
    />
  </Box>
);


export const BuildingCompleted = (props: any) => (
    <img
      src={BuildingCompletedIcon}
      alt="building completed icon"
      {...props}
      style={{ height:'100%', width: '100%' }}
    />
);